<template>
    <b-row class="border rounded pb-1">
        <b-button style="padding:0px 5px; font-size: 1.8rem; margin-left:auto;" variant="danger" @click="selfRemove">×</b-button>
        <b-col 
            cols="12" 
            v-for="(content,i) in dataValue"
            :key="i"
            class="mt-1"        
        >
            <b-input-group
                v-if="!(typeof dataValue[i] == 'object')"
            >
                <b-form-input 
                    preapend 
                    :placeholder="'field ' + i" 
                    v-model="dataValue[i]"
                ></b-form-input>
                <b-input-group-append>
                    <b-button variant="danger" @click="removeField(i)">×</b-button>
                </b-input-group-append>
            </b-input-group>
            <object-form 
                v-else-if="dataValue[i]!==undefined" 
                v-model="dataValue[i]"
            ></object-form>
        </b-col>
        <b-col cols="12">
            <add-field-button  v-if="dataValue!==undefined" @update-form="update" :form="dataValue">Add Field</add-field-button>
        </b-col>
    </b-row>
</template>
<script>
import {Fragment} from 'vue-fragment'
import AddFieldButton from './AddFieldButton.vue'

export default{
    props:['value'],
    name:'array-form',
    components:{
        Fragment,
        ObjectForm:()=>import("./ObjectForm.vue"),
        AddFieldButton,
    },
    model:{
        prop:'value',
        event:'update-value'
    },
    watch:{
        value(val){
            this.subprop=''
            this.subsubprop=''
            this.dataValue=val
        },
        dataValue(val){
            this.$emit('update-value',val)
        }
    },
    mounted(){
        this.dataValue = this.value
    },
    data(){
        return {
            dataValue:[],
            subprop:'',
            subsubprop:'',
            optionsTypes:[
                { label:'texto', value:'' },
                { label:'formulario', value:{} },
                { label:'numerico', value:0},
            ]
        }
    },
    methods:{
        removeField(i){
            this.dataValue.splice(i,1)
        },
        selfRemove(){
            this.dataValue=undefined
        },
        update(v){
            console.log(v)
            this.dataValue = v
        }
    }
}
</script>
<style scoped>
div.border{
    padding: .2rem;
}
</style>