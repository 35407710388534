<template>
    <div class="container-all " dir="ltr">
        <b-row class="floatup" v-if="!hide">
            <b-button class="btn-close" @click="hide=true">×</b-button>
            <b-col cols="12" class="margin-t-1" v-if="!isFormArray">
                <b-form-input placeholder="field name" v-model="newField"></b-form-input>
            </b-col>
            <b-col cols="12" class="margin-t-1">
                <v-select placeholder="field type" :options="optionsTypes" v-model="newFieldType"></v-select>
            </b-col>
            <b-col cols="12" class="margin-t-1">
                <b-button class="w-100" variant="secondary" @click="updateForm">Finish</b-button>
            </b-col>
        </b-row>
        <b-button class="w-100 mt-1" @click="hide=false"><slot></slot></b-button>
    </div>
</template>
<script>
import vSelect from 'vue-select'

export default {
    name:'add-field-button',
    components:{
        vSelect,
    },
    props:['form'],
    data(){
        return {
            formData:{},
            newField:'',
            hide:true,
            newFieldType:{},
            optionsTypes:[
                { label:'text', value:'' },
                { label:'form', value:{} },
                { label:'list', value:[]},
                { label:'number', value:0},
                { label:'bool', value:false},
            ]
        }
    },
    computed:{
        isFormArray(){
            return this.form && this.form.length!==undefined
        }
    },
    methods:{
        updateForm(){
            if(this.isFormArray) {
                this.form.push(this.newFieldType.value)
            } else {
                this.form = {...this.form, [this.newField]:this.newFieldType.value}
            }
            console.log(this.form)
            this.$emit('update-form',this.form)
            this.hide=true
        }
    }
}

</script>
<style>
.margin-t-1{
    margin-top:.4rem;
}
.floatup{
    position:absolute;
    background-color:#161d31;
    bottom: 3rem;
    z-index: 11;
    padding:0rem .3rem 1rem .3rem;
    margin-top: 1rem;
    border-radius: 8px;
    box-shadow: 0px 5px 20px rgba(0,0,0,.5);
}
.btn-close{
    padding:.0rem .4rem;
    position: relative;
    left:95%;
    bottom: 10px;
    font-size: 2rem;
}
</style>