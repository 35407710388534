<template>
  <child-item :child='item'></child-item>
</template>

<script>

export default {
  name:"item",
  components: {
    ChildItem: () => import('./child.vue')
  },
  props:['item'],
  
}
</script>

<style>
.can-select:hover{
  border: 2px dotted white;
  cursor: pointer;
}
</style>