<template>
	<div>
		<div class="tree-container">
			<div class="mt-1 w-100">
				<b-button 
					class="m-0 ml-1" 
					style="padding:.05rem .2rem" 
					variant="link"
					@click="addElement('-')"
				><b-icon icon="grid1x2-fill"></b-icon></b-button>
				<b-button 
					variant="link" 
					class="p-0 ml-1" 
					@click="editHandler('-')"
					style="font-weight:bold;font-size: 1.2rem;"
				>
					root <b-icon class="ml-1" icon="diagram3"></b-icon>
				</b-button>
			</div>
			<div class="ml-1">
				<homepage-tree-item 
					@edit-element="editHandler" 
					@delete-element="deleteHandler"
					@add-element="addElement"
					:items="homepage.layout" 
					path=""
				/>
			</div>
		</div>
	</div>
</template>
<script>
import HomepageTreeItem from './HomepageTreeItem.vue'
import { BButton } from 'bootstrap-vue'
import FormEl from './forms/FormEl.vue'
export default{
	name:'homepage-tree',
	props:['homepage'],
	components:{
		HomepageTreeItem,
		BButton,
		FormEl
	},
	methods:{
		editHandler(path){
			console.log(path)
			this.$emit("edit-element-clicked",path.split(''))
		},
		deleteHandler(path){
			this.$emit("delete-element-clicked",path.split(''))
		},
		addElement(path){
			console.log(path)
			this.$emit("add-child-element",path.split(''))
		}
	}
}
</script>
<style scoped>
.tree-container{
	height: 600px;
	min-height: 500px;
	overflow: scroll;
	scroll-behavior: smooth;
	background: #00000040;
	border-radius: 10px;
}
.tree-container::-webkit-scrollbar {
  -webkit-appearance: none;
}
.tree-container::-webkit-scrollbar:vertical {
  width:10px;
}
.tree-container::-webkit-scrollbar-button:increment,.tree-container::-webkit-scrollbar-button {
    display: none;
} 

.tree-container::-webkit-scrollbar:horizontal {
    height: 10px;
}

.tree-container::-webkit-scrollbar-thumb {
	background-color: #797979;
	border-radius: 20px;
}

.tree-container::-webkit-scrollbar-track {
    border-radius: 10px;
}
</style>