<template>
	<b-row v-if="node">
		<h4 class="ml-1 mt-1">Editar <span class="px-1" style="color:white;font-weight:bold;background:var(--primary);border-radius:9999px;">{{node.el}}</span></h4>
		<b-col cols="12">	
			<form-el @updated="$emit('update-homepage',$event)" v-model="node.props"></form-el>
		</b-col>
		<b-col 				
			class="w-100 mt-1 p-1 border rounded" 
			cols="12"
		>
			<b-row>
				<b-col cols="3">
					<label for="hide">hide</label>
					<b-form-checkbox 
						switch
						v-model="node.hide"
					></b-form-checkbox>
				</b-col>
				<b-col cols="9">
					<label for="content">content</label>
					<b-form-input 
						placeholder="text content of element" 
						v-model="node.content"
					></b-form-input>
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>
<script>

import { BFormInput,BRow,BCol,BButton } from 'bootstrap-vue' 
import FormEl from './FormEl.vue'
import vSelect from 'vue-select'
export default {
	props:['path','homepage'],
	name:'form-from-el',
	model:{
		prop:'homepage',
		event:'update-homepage'
	},
	components:{
		BFormInput,
		vSelect,
		BRow,
		BCol,
		BButton,
		FormEl
	},
	watch:{
		path(val){
			this.node=this.search(this.homepage.layout,val)
		},
		node(val){
			console.log(val)
			this.$emit('update-homepage',this.homepage)
		}
	},
	data(){
		return {
			node:{},
		}
	},
	mounted(){
		this.node=this.search(this.homepage.layout,this.path)
	},
	methods:{
		search(tree,paths){
			let path = Number(paths[0])
			if (paths.length==1){
				return tree[path]
			}else{
				if (tree[path].child){
					return this.search(tree[path].child, paths.slice(1))
				}else{
					return tree[0]
				}
			}
		}
	},
	
}
</script>