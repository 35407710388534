<template>
	<div class="rounded-my preview" ref="preview" :style="{height: this.height}" >
		<my-iframe
			 v-if="homepage"
			 :is-edit="isEdit"
			 @onClicked="iframeClickHandle"
       class="shadow-lg"
			:style="{
					...previewSize,
					pointerEvents : 'all',
					border:'none',
					backgroundColor:homepage.background_color,
					transform:scale+' translate(0,0)' 
			}
			">
				<html>
					<head v-html="head"></head>
					<body>
						<Container :content="homepage">
						</Container>
						<div v-html="`
							<script src='https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js' integrity='sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN' crossorigin='anonymous'></script>
							<script src='https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.min.js' integrity='sha384-+sLIOodYLS7CIrQpBjl+C7nPvqq+FbNUBDunl/OZv93DB7Ln/533i8e/mZXLi/P+' crossorigin='anonymous'></script>
						`">
					</div> 
				</body>
			</html>
		</my-iframe>
		<span v-else-if="!homepage">Loading preview...</span>
	</div>
</template>
<script>
import vSelect from 'vue-select'
import Container from './container.vue'
import MyIframe from './MyIframe'
export default{
	name:"preview-homepage",
	components:{
		vSelect,
		Container,
		MyIframe
	},
	data(){
		return {
			head:''
		}
	},
	methods : {

		iframeClickHandle(event){
			console.log("onClicked")
			console.log(event)
			console.log('searching in homePage by id: ', event.id)
			this.$emit("clicked", event)
		},
		
	},
	computed:{
		scale(){
			return {
				phone:'',
				tablet:'scale(.6)',
				laptop:'scale(.6)'
			}[this.size]
		},
		previewSize(){
			return {
				phone:{width:'350px',height:'500px'},
				tablet:{width:'750px',height:'800px'},
				laptop:{width:'1024px',height:'800px'},
			}[this.size]
		}
	},
	beforeMount(){
		this.head = `
		<head>
			${document.head.innerHTML}
			<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
			<link href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.15/tailwind.min.css" rel="stylesheet" />
		</head>`
	},
  props:['homepage','size','tempt', 'isEdit', 'height'],
}
</script>
<style scoped>
.rounded-my{
	border:none;
	border-radius: 10px;
}
.tempt{
	transition:.3s;
	opacity:.5;
}
.preview{
	width:100%;
	overflow: scroll;
	display: grid;
	place-items: center;
	padding: 10px;
}
.preview::-webkit-scrollbar {
  -webkit-appearance: none;
}
.preview::-webkit-scrollbar:vertical {
  width:10px;
}
.preview::-webkit-scrollbar-button:increment,.preview::-webkit-scrollbar-button {
    display: none;
} 

.preview::-webkit-scrollbar:horizontal {
    height: 10px;
}

.preview::-webkit-scrollbar-thumb {
	background-color: #797979;
	border-radius: 20px;
}

.preview::-webkit-scrollbar-track {
    border-radius: 10px;
}
</style>