<template>
  <div class="position-absolute zindex-0" v-if="content" :style="dynamicStyles">
    <template v-for="(layout,i) in content.layout" >
      <component v-bind:is="layout.el" v-bind="layout.props" :id="layout._id" :key="layout._id||i">
        <template v-for="(child, i) in layout.child">
          <child :child='child' :key="i"></child>
        </template>
        <template>
          {{ layout.content ? layout.content:'' }}
        </template>
      </component>
    </template>
  </div>
</template>

<script>
import Child from './child.vue'
export default {
  name:"ContainerWeb",
  props:['content'],
  components:
  {
    Child
  },
  computed: {
    dynamicStyles() {
      return {
        backgroundColor: this.content.background_color || ''
      };
    }
  }
}
</script>

<style>

</style>