<template>
  <img :src="getImgPath" alt="" />
</template>

<script>
export default {
  name: "MultiLangImg",
  computed: {
    getImgPath() {
      const { imgPaths } = this.$attrs;
      console.log(this.$i18n)
      console.log(imgPaths[this.$i18n.locale]);
      return imgPaths[this.$i18n.locale];
    },
  },

};
</script>

<style scoped></style>
