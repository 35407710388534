<template>
    <object-form 
        v-model="data"
    />
</template>
<script>
// import { Fragment } from 'vue-fragment'
import ObjectForm from './ObjectForm.vue'
import vSelect from 'vue-select'
export default {
    name:"FormEl",
    components:{
        // Fragment,
        vSelect,
        ObjectForm
    },
    props:['data'],
    model:{
        prop:'data',
        event:'update-data'
    },
    watch:{
        data(v){
            this.form = this.data
        }
    },
    computed:{
        form:{
            get(){
                return this.data
            },
            set(val){
                this.$emit('update-data',val)
            }
        }
    },
    data(){
        return { }
    },
    methods:{}
}
</script>