<template>
  <component v-bind:is="child.el" :id="child._id" v-bind="child.props">
    <template v-if="child.child && child.child.length > 0">
      <template v-for="(item, i) in child.child">
        <item class="" :item="item" :key="i"></item>
      </template>
    </template>
    <template>
        {{ child.content ? child.content:'' }}
    </template>
  </component>
</template>

<script>
import Item from './item.vue'
import MultiLangImg from "@/views/components/homepage/widgets/image/MultiLangImg.vue";
export default {
  name: "child",
  components:
  {
    Item,
    MultiLangImg
  },
  props:['child']
}
</script>

<style>
</style>