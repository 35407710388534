<template>

  <div class="w-100 mx-auto home-page-edit">
    <HomePagesSelectDesign
      v-if="!isEditing"
      :designs="templates"
      :loading="loadingTemplates"
      @selected="setSelectedTemplate"
    />

    <b-modal
      v-model="modal.show"
      no-close-on-esc
      no-close-on-backdrop
      size="sm"
      cancel-variant="outline-secondary"
      centered
      :title="modal.title"
      hide-footer
      hide-header-close
    >
      <b-card>
        <div
          class="d-flex flex-column flex-wrap align-items-center justify-content-center"
        >
          <div class="mb-1">{{ modal.subtitle }}</div>
          <b-spinner
            v-if="modal.loading"
            type="grow"
            label="Spinning"
            class="mr-1"
            small
          ></b-spinner>
        </div>
      </b-card>
    </b-modal>

    <div
      v-if="isEditing || (templates.length && !isEditing && selectedTemplate)"
      class="row"
    >
      <b-col xl="8">
        <b-card>
          <b-row cols="1" cols-sm="2">
            <b-col sm="4" tag="h5" class="text-left ma-auto">{{
              $t("tooltips.preview")
            }}</b-col>
            <b-col sm="12" md="8" class="text-md-right px-0">
              <b-button
                size="sm"
                class="ml-1 text-capitalize"
                :variant="size === breackpoint ? 'primary' : 'link'"
                v-for="(breackpoint, i) in sizes"
                @click="size = breackpoint"
                :key="i"
              >
                <b-icon class="d-none d-sm-inline mr-icon" :icon="breackpoint"></b-icon>
                {{ breackpoint }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="bg-transparent preview-box">
          <preview-homepage
            v-if="showPreview"
            ref="previewHomepage"
            :is-edit="true"
            height="70vh"
            :homepage="homePage"
            :tempt="temptPath"
            :size="size"
            @clicked="handleClickPreviewHomePage"
          ></preview-homepage>
        </b-card>
      </b-col>

      <b-col xl="4">
        <b-card>
          <b-form @submit.prevent="handleSubmit">
            <div v-if="editingRoot">
              <HomePagesCollapse
                  v-model="collapse.details"
                  :title="String($t('labels.detail'))"
              >
                <slot>

                  <b-list-group>
                    <b-list-group-item class="list-item">
                      <label for="id">{{ $t("labels.name") }}</label>
                      <b-form-input
                          id="id"
                          :state="!!homePage.name"
                          v-model="homePage.name"
                          :placeholder="$t('labels.name')"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback" class="text-capitalize">
                        {{ $t('required_value') }}
                      </b-form-invalid-feedback>
                    </b-list-group-item>

                    <b-list-group-item class="list-item">
                      <label for="id">{{ $t("labels.background-color") }}</label>
                      <b-form-input
                          id="id"
                          v-model="homePage.background_color"
                          type="color"
                          :placeholder="$t('labels.backgroundcolor')"
                      >
                      </b-form-input>
                    </b-list-group-item>
                  </b-list-group>
                </slot>
              </HomePagesCollapse>

              <HomePagesCollapse
                class="mt-1"
                v-if="selectedItem"
                v-model="collapse.image"
                :title="String($t('labels.image'))"
              >
                <slot>
                  <b-list-group>
                  <b-list-group-item class="list-item">
                    <img
                      v-if="selectedItem.el === 'img'"
                      alt=""
                      style="
                        object-fit: contain;
                        height: 200px;
                        width: 100%;
                        margin: 10px;
                      "
                      :src="selectedItem.props.src"
                    />

                    <img
                      v-else-if="selectedItem.el === 'multi-lang-img'"
                      alt=""
                      style="
                        object-fit: contain;
                        height: 200px;
                        width: 100%;
                        margin: 10px;
                      "
                      :src="selectedItem.props.imgPaths[$i18n.locale]"
                    />
                      
                    <section class="w-100 py-1">
                      <b-badge v-if="selectedItem.size">
                          {{$t('size')}}: {{selectedItem.size}}
                      </b-badge>
                    </section>

                    <label for="id">{{ $t("labels.change_image") }}</label>

                    <image-field-form
                      :text="$t('labels.image')"
                      class="w-100"
                      :single-button="true"
                      :value="''"
                      :size-validation="selectedItem.size || null"
                      @error="errorInLoadImage"
                      @uploadedImage="onUploadedImage($event)"
                      path="homePage"
                    />
                  </b-list-group-item>
                  </b-list-group>
                </slot>
              </HomePagesCollapse>
            </div>

            <div>
              <b-button :disabled="!homePage.name" type="submit" class="my-3 w-100" variant="primary">
                {{
                  isEditing ? $t("update_home_page") : $t("create_home_page")
                }}
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </div>
  </div>
</template>
<script>
import {
  BModal,
  BCard,
  BAlert,
  BLink,
  BCol,
  BRow,
  BForm,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormGroup,
} from "bootstrap-vue";
import {
  ref,
  onUnmounted,
  computed,
  getCurrentInstance,
  onMounted,
} from "@vue/composition-api";
import router from "@/router";
import {useRoute, useRouter} from "@core/utils/utils";
import vSelect from "vue-select";
import store from "@/store";
import homePageStoreModule from "./homePageStoreModule";
import PreviewHomepage from "@/views/components/homepage/preview-homepage.vue";
import HomepageTree from "@/views/components/homepage/HomepageTree.vue";
import FormFromEl from "@/views/components/homepage/forms/FormFromEl.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@/libs/axios";
const APP_URL = process.env.VUE_APP_URL;
import { useToast } from "vue-toastification/composition";
import HomePagesCollapse from "@/views/components/whitelabel-templates/App/config/PageEdit/HomePageManagement/external/homePages/home-pages-collapse/HomePagesCollapse.vue";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import { set } from "lodash";
import HomePagesSelectDesign from "@/views/components/whitelabel-templates/App/config/PageEdit/HomePageManagement/external/homePages/home-pages-select-design/HomePagesSelectDesign.vue";
import i18n from "@/libs/i18n";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import {showErrorToast} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";

export default {
  components: {
    HomePagesSelectDesign,
    ImageFieldForm,
    HomePagesCollapse,
    BFormGroup,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BAlert,
    BLink,
    PreviewHomepage,
    BFormInput,
    BForm,
    BModal,
    vSelect,
    BButton,
    HomepageTree,
    FormFromEl,
  },
  setup(props, {emit}) {
    const vm = getCurrentInstance();
    const appUrl = ref(APP_URL);
    const { route } = useRouter();
    const toast = useToast();
    const path = ref(null);
    const selectedTemplate = ref(null);
    const isEditing = ref(null);
    const sizes = ref(["phone", "tablet", "laptop"]);
    const editingRoot = ref(true);
    const collapse = ref({
      details: false,
      image: false,
    });
    const temptPath = ref("");
    const size = ref("phone");
    const newElement = ref("");
    const elementType = ref("");
    const templates = ref([]);
    const confirnDeleteModal = ref([]);
    const HOMEPAGE_APP_STORE_MODULE_NAME = "app-homepage";
    const homePage = ref({});
    const previewHomepage = ref();
    const design = ref();

    const widgets = ref(["container", "slider"]);

    const images = ref([]);
    const designs = ref([]);
    const selectedItem = ref(null);
    const loadingTemplates = ref(false);
    const modal = ref({
      show: false,
      title: "",
      subtitle: "",
      loading: false,
    });

    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    // Register module
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);

    //editing template
    const editTemplate = computed(() => {
      return store.state["app-whitelabel-templates"].editTemplate;
    });


    const setSelectedTemplate = (value) => {
      selectedTemplate.value = null;
      if (value) {
        selectedTemplate.value = value;
        homePage.value = selectedTemplate.value;
        traverse(homePage.value.layout, true);
      }
    };

    const showPreview = computed(() => {
      return !!isEditing;
    });

    const getHomePageOptions = async () => {
      try {
        loadingTemplates.value = true;
        const response = await fetch(`${appUrl.value}/homepages/options`);
        const dataJson = await response.json();
        return dataJson;
      } catch (err) {
        console.error(err);
      } finally {
        loadingTemplates.value = false;
      }
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          homepage: value || homePage.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: "templateData.homepage",
            }
        );
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const editHomePage = async () => {
      try {
        traverse(homePage.value.layout, false); //delete all ids
        await setModal({
          show: true,
          title: "HomePageManagement",
          sub: "",
          loading: true,
        });
        const response = await axios.put(
          `${appUrl.value}/homepages/${router.currentRoute.params.id}`,
          homePage.value
        );

        toast({
          component: ToastificationContent,
          props: {
            title: vm.proxy.$t("home_page_updated"),
            icon: "EditIcon",
            variant: "success",
          },
        });

        await setModal({
          loading: false,
          callback: () => (modal.value.show = false),
        });

        updatePreview(response.data)
        await updateTemplate()
        
        let query = {}
        const {templateId} = route.value.query

        if(templateId) {
          query = {id: templateId}
        }

        await router.push({name: "app-template-preview", query});

      } catch (err) {
        console.error(err);
        await setModal({
          loading: false,
          error: true,
          callback: () => (modal.show = false),
        });
      }
    };

    const saveHomePage = async () => {
      try {
        traverse(homePage.value.layout, false); //delete all ids
        if (homePage.value._id && homePage.value.updatedAt && homePage.value.original_name) {
          delete homePage.value._id;
          delete homePage.value.updatedAt;
          delete homePage.value.original_name;
        }
        homePage.value.whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id
        homePage.value.templateId = editTemplate.value._id

        await setModal({
          show: true,
          title: "HomePageManagement",
          sub: "",
          loading: true,
        });


        const response = await axios.post(
          `${appUrl.value}/homepages`,
          homePage.value
        );

        toast({
          component: ToastificationContent,
          props: {
            title: vm.proxy.$t("homepage_created"),
            icon: "success",
            variant: "success",
          },
        });
        await setModal({
          loading: false,
          callback: () => (modal.value.show = false),
        });

        updatePreview(response.data)
        await updateTemplate()

        let query = {}
        const {templateId} = route.value.query

        if(templateId) {
          query = {id: templateId}
        }

        await router.push({name: "app-template-preview", query});
      } catch (err) {
        console.log(err);
        await setModal({
          loading: false,
          error: true,
          callback: () => (modal.show = false),
        });
      }
    };

    const handleSubmit = (ev) => {
      if (isEditing.value) {
        editHomePage();
      } else {
        saveHomePage();
      }
    };

    if (route.value.params.id) {
      isEditing.value = true;
    } else {
      isEditing.value = false;
    }

    onMounted(() => {
      if (isEditing.value) {
        setModal({
          show: true,
          title: vm.proxy.$t("loading_data"),
          sub: "",
          loading: true,
        });
        store
          .dispatch("app-homepage/fetchHomePage", {
            id: router.currentRoute.params.id,
          })
          .then(async (response) => {
            homePage.value = response.data;
            traverse(homePage.value.layout, true);
            await setModal({
              loading: false,
              callback: () => (modal.value.show = false),
            });
          })
          .catch(async (error) => {
            if (error.response.status === 404) {
              homePage.value = undefined;
            }
            await setModal({
              loading: false,
              error: true,
              callback: () => (modal.show = false),
            });
          });
      }
    });

    if (!isEditing.value) {
      getHomePageOptions()
        .then((res) => {
          templates.value = res;
        })
        .catch((err) => {
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          router.push({ name: "apps-homePages-list" });
        });
    }

    if (!store.hasModule(HOMEPAGE_APP_STORE_MODULE_NAME)) {
      store.registerModule(HOMEPAGE_APP_STORE_MODULE_NAME, homePageStoreModule);
    }
    onUnmounted(() => {
      if (store.hasModule(HOMEPAGE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(HOMEPAGE_APP_STORE_MODULE_NAME);
      }
    });

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const setHomePageValue = (value) => {
      switch (selectedItem.value.el) {
        case "img":
          set(selectedItem.value, "props.src", value);
          break;
        case "multi-lang-img":
          console.log(i18n.locale);
          set(selectedItem.value, `props.imgPaths.${i18n.locale}`, value);
          break;
        default:
          console.log("Unrecognized option");
          break;
      }
      //Lodash's _.set() method is used to set the value of a property on an object in a nested manner.
    };

    // const onChangeImage = async (value) => {
    //   if (value === "reload" && images.value?.routes?.length) {
    //     await loadHomePageImages();
    //   } else if (value === "error") console.log("error");
    //   else {
    //     setHomePageValue(value);
    //   }
    // };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        setHomePageValue(data.files[data.files.length - 1]);
      }
    };

    const findById = (obj, id) => {
      console.log("buscando el id" + id, obj);
      // Check if the current one matches
      if (obj._id === id) {
        return obj;
      }
      // Array
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          const found = findById(obj[i], id);
          if (found) {
            return found;
          }
        }
      }
      // Object -> search in child
      else if (obj.child) {
        for (let c of obj.child) {
          const found = findById(c, id);
          if (found) {
            return found;
          }
        }
      }
      // Did not find
      return undefined;
    };

    // traverse(obj) {
    // 	if (obj.length){
    // 		obj.forEach( e => {
    // 			e._id = this.generateId()
    // 			if(e.child)
    // 				this.traverse(e.child)
    // 		})
    // 		return this.homepage
    // 	}
    // 	return {}
    // },

    const traverse = (obj, addIds) => {
      //Validate object
      if (!obj) {
        return;
      }

      if (addIds) {
        //Assign ID to current
        obj._id = generateId();
      } else {
        //Delete id
        delete obj._id;
      }

      // Array
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          traverse(obj[i], addIds);
        }
      }

      // Object -> child
      else if (obj?.child) {
        for (let c of obj.child) {
          traverse(c, addIds);
        }
      }
    };

    const generateId = () => {
      return "_" + Math.random().toString(36).substr(2, 9);
    };

    const handleClickPreviewHomePage = (event) => {
      console.log("desdeeeeeeeeeeee el componente tipo");
      console.log(event);
      const selectedElement = findById(homePage.value.layout, event.id);
      console.log(selectedElement);
      if (!selectedElement) {
        selectedItem.value = null;
        collapse.value.image = false;
        return
      }
      if (
        selectedElement.el === "img" ||
        selectedElement.el === "multi-lang-img"
      ) {
        setTimeout(() => {
          collapse.value.image = true;
        }, 50);
        selectedItem.value = selectedElement;
      }
    };

    const loadHomePageImages = async () => {
      await store
        .dispatch(
          "app-homepage/fetchHomePageImage",
          store.state.whitelabelCurrencyNabvar.whitelabel.client
        )
        .then((response) => {
          images.value = response.data;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 404) {
            images.value = [];
          }
        });
    };
    // loadHomePageImages();

    const setModal = ({ show, title, sub, loading, callback, error }) => {
      if (loading) {
        modal.value.show = show;
        modal.value.title = title;
        modal.value.subtitle = sub;
        modal.value.loading = true;
        return Promise.resolve(true);
      } else {
        modal.value.loading = false;
        modal.value.subtitle = error
          ? vm.proxy.$t("generic_error")
          : vm.proxy.$t("filled");
        return new Promise((resolve) => {
          setTimeout(() => {
            callback();
            resolve();
          }, 500);
        });
      }
    };

    return {
      size,
      sizes,
      design,
      homePage,
      selectedTemplate,
      path,
      templates,
      newElement,
      isEditing,
      editingRoot,
      collapse,
      confirnDeleteModal,
      previewHomepage,
      temptPath,
      elementType,
      widgets,
      showPreview,
      editTemplate,
      handleSubmit,
      handleClickPreviewHomePage,
      images,
      selectedItem,
      errorInLoadImage,
      // onChangeImage,
      onUploadedImage,
      designs,
      setSelectedTemplate,
      loadingTemplates,
      modal,
    };
  },
};
</script>
<style scoped>
.btn-container {
  display: grid;
  margin-top: 0.5rem;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 0.5rem;
}

.preview-box {
  min-height: 540px;
}

@media (max-width: 767px) {
  .home-page-edit {
    padding-top: 60px;
  }
}

.mr-icon {
  margin-right: 0.3em
}

.list-item {
  padding-bottom: 2.5em;
}

.disabled {
  pointer-events: none;
}
</style>
