<script>
import {BButton, BModal} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "HomePagesSelectDesign",
  components: {vSelect, BButton, BModal},
  props : ['designs', "loading"],

  data() {
    return {
      selected: null
    }
  },

  methods: {
    onSave() {
      if (!this.designs.length) this.$router.push({name:'apps-homePages-list'})
      this.$emit('selected', this.selected);
      this.$bvModal.hide('select-design')
    }
  }
}

</script>

<template>
  <div>
    <b-modal id="select-design"
             footer-class="border-top-0"
             no-close-on-backdrop
             no-close-on-esc
             centered
             visible
             style="width: 300px"
             size="sm"
             hide-header-close
             header-bg-variant="white">

      <template #default="{ hide }">
        <div class="p-1">
          <div>
            <h5>{{ $t('select_design') }}</h5>
          </div>
          <div class="pt-1 pb-2">
            <v-select :options="designs" :loading="loading" label="original_name" v-model="selected">
            </v-select>
          </div>
        </div>
      </template>

      <template #modal-footer="{ ok, cancel, hide }">
        <div class="p-1">
        <span v-if="loading">{{$t('loading_data')}}</span>
        <b-button v-else @click="onSave()" size="md" :variant="designs.length ? 'primary': 'danger'" :disabled="loading || (!!designs.length && !selected)">
          {{ designs.length ? $t('buttons.accept') : $t('list_empty') }} 
        </b-button>
        </div>
      </template>

    </b-modal>

  </div>
</template>

<style scoped lang="scss">

</style>