<script>

import {BCol, BFormInput} from "bootstrap-vue";

export default {
  name: "HomePagesCollapse",

  components: {BFormInput, BCol},

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    value: {
      type: Boolean,
      default: ''
    },
    title: {
      type: String
    }
  },
    
  methods: {
    emitInput(e) {
      this.$emit('input', e)
    }
  }
}

</script>

<template>
  <div :class="value ? '' : 'border-bottom border-gray'">

    <div @click="emitInput(!value)"
         class="media pb-1 "
         :aria-expanded="value ? 'true' : 'false'"
         aria-controls="collapse-main">

      <div class="d-flex justify-content-between w-100" style="user-select: none; cursor: pointer">
        <h4>
          {{ title }}
        </h4>

        <div>
          <feather-icon
              size="20"
              :icon="value ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          />
        </div>
      </div>


    </div>


    <b-collapse v-model="value">
      <div>
        <slot/>
      </div>

    </b-collapse>
  </div>
</template>

<style scoped lang="scss">

</style>