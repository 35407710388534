<template>
    <div
        v-if="(items && items.length>0)" 
    >
        <div
            class="tree-node-child mt-1 mt-1 mt-1 mt-1''" 
            v-for="(child,i) in items" 
            :key="path+i+Math.random().toString(16)"
        >
            <template v-if="child!==undefined">
                <b-dropdown 
                    variant="link"
                    no-caret
                >
                    <template #button-content>
                        <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item @click="$emit('edit-element',path+i)">
                        <b-icon class="mr-1" variant="primary" icon="pencil-square"></b-icon>
                        edit
                    </b-dropdown-item>
                    <b-dropdown-item @click="$emit('add-element',path+i)">
                        <b-icon class="mr-1" variant="primary" icon="grid1x2-fill"></b-icon>
                        add new
                    </b-dropdown-item>
                    <b-dropdown-item @click="$emit('delete-element',path+i)">
                        <b-icon class="mr-1" variant="danger" icon="x"></b-icon>
                        delete
                    </b-dropdown-item>
                </b-dropdown>
                <b-button 
                    variant="link" 
                    class="m-0 p-0" 
                    @click="showNext(path+i)"
                >
                    {{child.el}}
                    <template v-if="child.child&&child.child.length>0">
                        <b-icon 
                            class="ml-1" 
                            :ref="'icon-left-'+path+i"                             
                            :icon="'caret-left-fill'"
                        ></b-icon>
                        <b-icon 
                            class="ml-1 d-none"
                            :ref="'icon-down-'+path+i" 
                            :icon="'caret-down-fill'"
                        ></b-icon>
                    </template>
                </b-button>
                <div :ref="'tree-node-'+path+i" class="d-none">
                    <homepage-tree-item
                        :key="path+i"
                        :path="path+i" 
                        :items="child.child"
                        @delete-element="$emit('delete-element',$event)"
                        @edit-element="$emit('edit-element',$event)"
                        @add-element="$emit('add-element',$event)"
                    />
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { Fragment } from 'vue-fragment' 
export default{
    props:['items','path'],
    name:'homepage-tree-item',
    components:{
        Fragment
    },
    methods:{
        showNext(node){
          try {
              console.log(this.$refs)
              this.$refs['tree-node-'+node][0].classList.toggle('d-none')
              this.$refs['icon-down-'+node][0].classList.toggle('d-none')
              this.$refs['icon-left-'+node][0].classList.toggle('d-none')
          } catch (e){
              console.log('no items found')
          }
        }
    }
}
</script>

<style scoped>
.tree-node{
    list-style:none;
    margin:0;
    padding: 0;
}
.tree-node-child{
    margin-left:1.5rem;
    transform: .2s;
    width: max-content;
}
</style>