<template>
    <b-row class="border rounded pb-1">
        <b-button 
            v-if="!cannot_be_deleted" 
            style="padding:0px 5px; font-size: 1.8rem; margin-left:auto;" 
            variant="danger" 
            @click="selfRemove"
        >×</b-button>
        <template v-if="dataValue!==undefined">
            <b-col
                    cols="12" 
                    v-for="(prop,i) in Object.keys(dataValue)"
                    :key="i"
                >
                    <b-input-group v-if="dataValue[prop]!==undefined">
                        <label class="w-100" :for="i+prop">{{prop}}</label>
                        <template v-if="!(typeof dataValue[prop] === 'object')">
                            <b-form-checkbox 
                                v-if="typeof dataValue[prop] == 'boolean'"
                                switch
                                v-model="dataValue[prop]"
                            >
                            </b-form-checkbox>
                            <b-form-input
                                v-else 
                                preapend 
                                :key="i" 
                                :id="i+prop" 
                                :placeholder="'edit '+prop" 
                                v-model="dataValue[prop]"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button variant="danger" @click="removeField(prop)">×</b-button>
                            </b-input-group-append>
                        </template>
                        <array-form
                            v-else-if="dataValue[prop].length!==undefined"
                            v-model="dataValue[prop]"
                        >
                        </array-form>
                        <object-form 
                            v-else
                            v-model="dataValue[prop]" >
                        </object-form>
                    </b-input-group>
            </b-col>
        </template>
        <b-col cols="12">
            <add-field-button @update-form="update" :form="dataValue">Add Field</add-field-button>
        </b-col>
    </b-row>
</template>
<script>
import {Fragment} from 'vue-fragment'
import AddFieldButton from './AddFieldButton.vue'
import ArrayForm from "./ArrayForm.vue"
export default{
    props:['value','cannot_be_deleted'],
    name:'object-form',
    components:{
        Fragment,
        ArrayForm,
        AddFieldButton,
    },
    watch:{
        value(newVal){
            this.dataValue=newVal
        },
        dataValue(newVal){
            this.$emit('update-value',newVal)
        }
    },
    model:{
        prop:'value',
        event:'update-value'
    },
    data(){
        return {
            dataValue:{}
        }
    },
    mounted(){
        this.dataValue = this.value
        console.log(this.dataValue)
        console.log(this.value)
    },
    methods:{
        selfRemove(){
            this.dataValue = undefined
        },
        removeField(field){
            this.dataValue[field]= undefined
        },
        update(v){
            console.log(v)
            this.dataValue = v
        }
    }
}
</script>
<style scoped>
div.border{
    padding: .2rem;
}
</style>