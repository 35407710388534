import Vue from "vue";

export default {
  name: "IFrame",
  props: ["props", "isEdit"],
  data() {
    return {
      iApp: null,
    };
  },
  render(h) {
    return h("iframe", {
      ref: "miIframe",
      on: {
        load: this.renderChildren,
      },
    });
  },
  updated() {
    setTimeout(()=>{
      if (this.isEdit) this.setImgStyles();
    },200)
  },
  
  //re-render the children of the iframe every time a change occurs
  beforeUpdate() {
    this.iApp.children = Object.freeze(this.$slots.default);
  },
  methods: {
    renderChildren() {
      const children = this.$slots.default;
      const body = this.$el.contentDocument.body;
      const el = document.createElement("div"); // we will mount or nested app to this element
      body.appendChild(el);

      const iApp = new Vue({
        name: "iApp",
        i18n: Object.freeze(this.$root.$i18n), // Pasar $i18n como una propiedad a la instancia de Vue
        data() {
          return {
            children: Object.freeze(children),
            selectedElement: null, //the element that is selected within the iframe
          };
        },

        render(h) {
          return h("div", this.children);
        },
      });

      // const observer = new MutationObserver(() => {
      //   // Cambios en el DOM de iApp
      //   console.log("cambios en el dom")
      // });

      // // Comenzar observación después del montado
      // iApp.$on('hook:mounted', () => {
      //   observer.observe(iApp.$el, {
      //     childList: true,
      //     subtree: true // observar dentro de elementos
      //   });
      // });
      //
      // // Desconectar observer en antesDestroy
      // iApp.$on('hook:beforeDestroy', () => {
      //   observer.disconnect();
      // });

      iApp.$mount(el);

      this.iApp = iApp;

      if (this.isEdit) this.setImgStyles();

      // Escuchar un evento emitido desde iApp
      // this.iApp.$on('otro-evento', (data) => {
      //   // alert("test")
      // });

      /**
       * If the iframe is in edit mode then this method calls another
       * to add a style to the selected elements and output them
       */
      this.iApp.$el.addEventListener("click", (event) => {
        console.log("An item was clicked inside iApp (iframe)");
        console.log("Clicked!", event.target);
        if (this.isEdit) {
          this.setSelectedElement(event.target);
        }
        console.log(event.target.tagName);
        console.log(event.target.id);
        // this.$emit("onClicked", { tagName : event.target.tagName, id : event.target.id })
      });

      // // Acceder a los métodos de ciclo de vida de iApp
      // this.iApp.$mount('#app'); // Montar la instancia de Vue en un elemento específico
      //
      // // Acceder a los mixins de iApp
      // this.iApp.mixin.miMetodoDelMixin();
      //
      // // Acceder a las directivas personalizadas de iApp
      // this.iApp.directives.miDirectivaPersonalizada();
      //
      // // Acceder a los filtros de iApp
      // this.iApp.filters.miFiltro();
      //
      // // Acceder a los componentes hijos de iApp
      // this.iApp.$children.forEach(child => {
      //   console.log(child);
      // });
      //
      // // Acceder a los eventos de ciclo de vida de iApp
      // this.iApp.$on('hook:mounted', () => {
      //   console.log('iApp ha sido montado');
      // });
      //
      // // Acceder a los watchers de iApp
      // this.iApp.$watch('miDato', (nuevoValor, valorAnterior) => {
      //   console.log('El valor de miDato ha cambiado a:', nuevoValor);
      // });
      //
      // // Acceder a los métodos de utilidad de iApp
      // this.iApp.$nextTick(() => {
      //   console.log('El DOM ha sido actualizado');
      // });
      //
      // // Acceder a los eventos de bus de iApp
      // this.iApp.$bus.$emit('mi-evento', data);
      //
      // // Acceder a los plugins de iApp
      // this.iApp.$myPlugin.metodoDelPlugin();
      //
      // // Acceder a los servicios de iApp
      // this.iApp.$service.metodoDelServicio();
      //
    },

    /**
     * defines styles for iframe images
     */
    setImgStyles() {
      const images = this.$el.contentDocument.body.querySelectorAll("img");
      console.log(images);
      console.log("todas las imagnees");
      images.forEach((image) => {
        image.classList.add("cursor-pointer");
        // image.classList.add("hover:bg-red-600");
        image.classList.add("transform");
        // image.classList.add("hover:scale-105");
        image.classList.add("transition");
        image.classList.add("duration-500");

        //We apply styles to all images except the one that is selected
        image.addEventListener("mouseenter", () => {
          images.forEach((i) => {
            if (i !== image) {
              i.style.filter = "blur(5px)";
            }
          });
        });

        //We remove styles from all images
        image.addEventListener("mouseleave", () => {
          images.forEach((i) => {
            i.style.filter = "";
          });
        });
      });
    },

    setSelectedElement(element) {
      console.log(element.tagName);
      const useHandleSelectedElementClass = this.handleSelectedElementClass();
      if (this.selectedElement) {
        useHandleSelectedElementClass.removeClassToSelectedElement(); //remove class if it is defined for that element
        if (this.selectedElement.id === element.id) { //if the elements match
          this.$emit("onClicked", { tagName: null, id: null }); //we deselect it
          this.selectedElement = null;
          return;
        }
      }
      this.selectedElement = element; //save the selected item
      useHandleSelectedElementClass.addClassToSelectedElement(); // we add class if it is defined for that element
      this.$emit("onClicked", { tagName: element.tagName, id: element.id }); //we emit the selected element
    },

    /**
     * It is responsible for defining classes for different elements that will 
     * be applied if the element is selected or not.
     */
    handleSelectedElementClass() {
      const imgClasses = [
        "border-2",
        "border-dashed",
        "border-red-600",
        "bg-black",
      ];
      const addClassToSelectedElement = () => {
        if (this.selectedElement.tagName === "IMG") {
          this.selectedElement.classList.add(...imgClasses); //Remove the dashed border classes from the previously selected element
        }
      };
      const removeClassToSelectedElement = () => {
        if (this.selectedElement.tagName === "IMG") {
          this.selectedElement.classList.remove(...imgClasses); //Remove the dashed border classes from the previously selected element
        }
      };

      return {
        addClassToSelectedElement,
        removeClassToSelectedElement,
      };
    },
  },
};
